/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { useTranslation } from "react-i18next";
// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();
  const {t} = useTranslation()

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" &&
        !["sign-in", "update-workshop/:id", "update-user/:id"].includes(route.path)
      ) {
        const isExcludedRoute = ["sign-in", "update-workshop/:id", "update-user/:id"].includes(route.path);
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
        <div className={`relative mb-3 flex ${!isExcludedRoute ? 'hover:cursor-pointer' : ''}`}>
          <li
            className={`my-[3px] flex items-center px-8 ${
              !isExcludedRoute ? 'cursor-pointer' : ''
            }`}
            key={index}
          >
                <span
                  className={`${
                    activeRoute(route.path) === true && route.icon
                      ? "font-bold text-[#E2A91B] dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.icon ? route.icon : null}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path) === true  && route.icon
                      ? "font-bold text-[#E2A91B] dark:text-white"
                      : "font-medium text-gray-600 mr-2"
                  }`}
                >
                  {t(route.name)}
                </p>
              </li>
              {activeRoute(route.path) && route.icon ? (
                <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-[#dd2d12] dark:bg-brand-400" />
              ) : null}
            </div>
          </Link>
        );
      }
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
