import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const PaymentToggle = () => {
    const { t } = useTranslation()
  const [paymentStatus, setPaymentStatus] = useState(false);

  const togglePaymentStatus = async () => {
    const jwt = localStorage.getItem('token');
    try {
      const response = await fetch('https://api.carwisi.com/application/v1/toggle-payment-status', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwt}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to toggle payment status');
      }

      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      Toast.fire({
        icon: t('success'),
        title: t('You Have Update App Payment Successfully'),
      })

      const result = await response.json();
      setPaymentStatus(result);
    } catch (error) {
      console.error('Error toggling payment status', error);
    }
  };

  useEffect(() => {
    const jwt = localStorage.getItem('token');
    const fetchPaymentStatus = async () => {
      try {
        const response = await fetch('https://api.carwisi.com/application/v1/check-paid-status', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch payment status');
        }

        const result = await response.json();
        setPaymentStatus(result);
      } catch (error) {
        console.error('Error fetching payment status', error);
      }
    };

    fetchPaymentStatus();
  }, []); 

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-2xl font-bold mb-4">{t('paymentStatus')}: {paymentStatus ? t('paid') : t('notPaid')}</h1>
      <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        checked={paymentStatus}
        onChange={togglePaymentStatus}
        className="sr-only peer"
      />
      <div
        className={`w-11 h-6 rounded-full peer ${
          paymentStatus
            ? 'bg-yellow-500 peer-checked:bg-yellow-600'
            : 'bg-red-500 peer-checked:bg-red-600'
        } peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600`}
      ></div>
      <span className="ms-3 text-sm font-medium dark:text-gray-300">
        {t('toggelP')}
      </span>
    </label>
    </div>
  );
};

export default PaymentToggle;
