/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import routes from "routes.js";
import { useTranslation } from "react-i18next";

const Sidebar = ({ open, onClose }) => {
  const { i18n, t } = useTranslation();
  const isDarkMode = document.body.classList.contains('dark');
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
      style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}
    >
      <span
        className={`absolute top-4 right-4 block cursor-pointer xl:hidden`}
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[60px] my-[18px] flex items-center`}>
        <div className="h-[180px] w-[180px] bg-contain dark:bg-dark bg-light" />
      </div>
      <div class="mt-[20px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
