import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { MdNotificationAdd } from 'react-icons/md';
import InputField from 'components/fields/InputField';
import { useUserInfo } from 'auth/userInfo';
import Modal from 'react-modal';
import moment from 'moment';
import { IoMdDoneAll } from 'react-icons/io';


const NotificationComponent = (props) => {
  const {columnsData} = props
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [tableData, setTableData] = useState([])
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationDate, setNotificationDate] = useState('');
  const {data: loggedUser, isLoading, isError} = useUserInfo()
  const [isNotificationSent, setNotificationSent] = useState(false);
  const { t } = useTranslation()

  const openModal = (userId) => {
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);
    useEffect(() => {
      const jwt = localStorage.getItem('token');
      const fetchUsers = async () => {
        try {
          const response = await fetch('https://api.carwisi.com/user/v1/all_users?fields=username,fullName', {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${jwt}`,
            },
          });
          if (!response.ok) {
            throw new Error('Failed to fetch workshop');
          }
          const workshopData = await response.json();
          console.log('CAR', workshopData)
          setTableData(workshopData);
        } catch (error) {
          console.error('Error fetching workshops', error);
        }
      };
  
      fetchUsers();
    }, []);
    useEffect(() => {
      const jwt = localStorage.getItem('token');
      const fetchCars = async () => {
        try {
          const response = await fetch(`https://api.carwisi.com/cars/v1/inspection/${selectedUserId}`, {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${jwt}`,
            },
          });
          if (!response.ok) {
            throw new Error('Failed to fetch workshop');
          }
          const workshopData = await response.json();
          setNotificationMessage(t("Inspections") + workshopData.oil_filter || workshopData.oil_change || workshopData.air_filter, workshopData.air_conditioner_filter);
            const currentDate = moment();
            const oneMonthFromCreated = moment(workshopData.createdDate, 'DD MMMM YYYY').add(1, 'month');
            const duration = moment.duration(oneMonthFromCreated.diff(currentDate));
            const days = Math.floor(duration.asDays());
            setNotificationDate(t("days") + days + " " + t("day"))
        } catch (error) {
          console.error('Error fetching workshops', error);
        }
      };
  
      fetchCars();
    }, [selectedUserId, t]);
    useEffect(() => {
      const fetchInitialState = async () => {
        const storedState = await localStorage.getItem(`${notificationMessage}-${selectedUserId}`);
        if (storedState) {
          setNotificationSent(storedState === 'true');
        }
      };
  
      fetchInitialState();
    }, [notificationMessage, selectedUserId]);
  const handleSendNotification = async () => {
    const jwt = localStorage.getItem('token');
    try {
      const response = await fetch(`https://api.carwisi.com/cars/v1/notify-car/${selectedUserId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          message: notificationMessage,
          date: notificationDate,
        }),
      });

      if (response.ok) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          Toast.fire({
            icon: t('success'),
            title: t('You Have Send Notification Successfully'),
          })
          closeModal();
          setNotificationSent(true);
          localStorage.setItem('selectedUserId', selectedUserId);
          localStorage.setItem(`${notificationMessage}-${selectedUserId}`, 'true');
        } else {
          const errorData = await response.json();
          const errorMessage = errorData.message || 'Failed to send notification';
  
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
    
          Toast.fire({
            icon: 'error',
            title: errorMessage,
          });
        }
    } catch (error) {
      console.error('Error sending notification', error);
    }
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: 5,
        },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      state: { pageIndex, pageSize, globalFilter },
    } = tableInstance;


    const check = (daysRemaining) => {
      const currentDate = moment();
      const oneMonthFromCreated = moment(daysRemaining, 'DD MMMM YYYY').add(1, 'month');
      const duration = moment.duration(oneMonthFromCreated.diff(currentDate));
  
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
  
      const formattedDuration = `${days}:${hours}:${minutes}:${seconds}`;
      return days
    }

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
    <div class="relative flex items-center justify-between">
      <div class="text-xl font-bold text-navy-700 dark:text-white">
        {t('User Authentication')} <span><InputField
      type="text"
      placeholder={t('Search')}
      value={globalFilter || ''}
      onChange={(e) => tableInstance.setGlobalFilter(e.target.value)}
    /></span>
      </div>
    </div>

    <div class="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
      <table {...getTableProps()} className="w-[90%]">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  className="border-b border-gray-200  pb-[10px] text-start dark:!border-navy-700"
                  >
                  <p className="text-xs tracking-wide text-gray-900 dark:text-white">
                  {t(column.render("Header"))}
                  </p>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            if(row.original.cars.length > 0) {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Avatar") {
                      data = (
                        <img
                        className="h-10 w-10 rounded-full"
                        src={cell.value || require('../../../../assets/img/avatars/Logo-English.png')}
                        alt="carwisi"
                        />
                      );
                    } 
                    else if (cell.column.Header === "Email") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }else if(cell.column.Header === "WorkShopName") {
                      data = (
                        row.original.cars.map(r => {
                          return r.map(i => (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {i.workshopName}
                          </p>
                          ))
                        })
                      )
  
                    }else if(cell.column.Header === "CarModel") {
                      data = (
                        row.original.cars.map(r => {
                          return r.map(i => {
                            return (
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {i.carModel}
                            </p>
                            )
                          })
                        })
                      )
  
                    }else if(cell.column.Header === "Inspection") {
                      data = (
                        row.original.cars.map(r => {
                          return r.map(i => {
                            return (
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {i.air_filter || i.oil_change || i.oil_filter || i.air_conditioner_filter}
                            </p>
                            )
                          })
                        })
                      )
  
                    }else if(cell.column.Header === "Car Number") {
                      data = (
                        row.original.cars.map(r => {
                          return r.map(i => {
                            return (
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {i.car_plate_number}
                            </p>
                            )
                          })
                        })
                      )
  
                    }else if(cell.column.Header === "DaysRemaining") {
                      data = (
                        row.original.cars.map(r => {
                          return r.map(i => {
                        
                            return (
                              <p className={`text-sm pl-8 font-bold ${check(i.createdDate) < 5 ? 'text-red-500' : 'text-navy-700 dark:text-white'}`}>
                                {check(i.createdDate)}
                              </p>
                            );                        
                          })
                        })
                      )
  
                    }else if (cell.column.id === "Actions") {
                      data = (
                          <div className="flex  gap-3 text-left">
                          {loggedUser && loggedUser._id !== row.original._id && (
                            <button className="h-6 w-6 bg-blue-500  rounded-md flex item-center justify-center"
                          onClick={() => openModal(row.original._id)}
                          >
                          <MdNotificationAdd className="h-4 w-4 mt-1 text-white" />
                          </button>
                          )}
                        </div>
                      );
                    }
                    return (
                      <td
                      className="pt-[10px] pb-[17px] sm:text-[14px]"
                      {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            } else {
              return (
                <div>
                  
                </div>
              )
            }
          })}
        </tbody>
      </table>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Notification Details"
        className="fixed inset-0 z-50 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black"
      >
        <div className="bg-white dark:bg-navy-800 w-96 p-6 shadow-2xl rounded-lg">
        <h2 className='text-2xl font-bold mb-4'>{t('Notifications')}</h2>
          <InputField
            type="text"
            value={notificationMessage}
            label={t('Message')}
            onChange={(e) => setNotificationMessage(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
          />
          <InputField
            type="text"
            value={notificationDate}
            label={t('Date')}
            onChange={(e) => setNotificationDate(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
          />
    <div className="flex justify-between mt-4">
      {localStorage.getItem('selectedUserId') === selectedUserId ? (
        <><div></div></>
      ) : (
              <button
              className="px-4 py-2 bg-blue-500 flex items-center justify-center gap-3 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200 active:bg-blue-800"
              onClick={handleSendNotification}
            >
              <span><MdNotificationAdd /></span> {t('Send Notification')}
            </button>
      )}
      <button
        className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-gray-700 dark:text-white hover:border-gray-400 dark:hover:border-gray-500 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 active:border-gray-500 dark:active:border-gray-700"
        onClick={closeModal}
      >
        {t('Close')}
      </button>
    </div>
          </div>
      </Modal>
      <div className="mt-4 flex justify-end items-center">
    <button
      onClick={() => tableInstance.previousPage()}
      disabled={!tableInstance.canPreviousPage}
      className="mr-2 cursor-pointer"
    >
      <FaAngleLeft size={20} />
    </button>
    <span>
      Page{' '}
      <strong>
        {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
      </strong>{' '}
    </span>
    <button
      onClick={() => tableInstance.nextPage()}
      disabled={!tableInstance.canNextPage}
      className="ml-2 cursor-pointer"
    >
      <FaAngleRight size={20} />
    </button>
  </div>
    </div>
  </Card>
  );
};


export default NotificationComponent;
