import Banner from "./components/Banner";
import Upload from "./components/Upload";

const ProfileOverview = () => {
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-full mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-5 lg:!mb-0">
          <Banner />
        </div>
        <div className="z-0 col-span-5 lg:!mb-0">
          <Upload />
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
