import React from 'react'
import CommentTable from './CommentTable'
import { columnsDataCheck } from '../variables/columnsData'

const Comment = () => {
  return (
    <div>
    <div className="mt-10 grid h-full grid-cols-1 gap-3">
      <CommentTable
      columnsData={columnsDataCheck}
      />
    </div>
  </div>
  )
}

export default Comment