import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useQuery } from 'react-query';
import { MdCheckCircle, MdCancel, MdOutlineError, MdNotificationAdd } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import Progress from "components/progress";
import { useTranslation } from "react-i18next";
import { FaAngleLeft, FaAngleRight, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { fetchWorkshops } from "api/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import InputField from "components/fields/InputField";
import Modal from 'react-modal';
import moment from 'moment';
import { IoNotificationsOffCircleOutline } from "react-icons/io5";

const ComplexTable = (props) => {
  const { columnsData } = props;
  const { t } = useTranslation()
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationDate, setNotificationDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [tableData, setTableData] = useState([])
  const [onlineStatus, setOnlineStatus] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  
  //const { data: tableData, error, isLoading } = useQuery('workshops', fetchWorkshops);
  const openModal = (userId) => {
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);
  const searchQuery = new URLSearchParams(location.search).get('search') || '';

  useEffect(() => {
    const jwt = localStorage.getItem('token');
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://api.carwisi.com/workshop/v1/all_workshop?fields=createdAt', {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch workshop');
        }
        const workshopData = await response.json();
        setTableData(workshopData);
        const onlineStatusMap = {};
        workshopData.forEach(workshop => {
          onlineStatusMap[workshop._id] = workshop.isOnline;
        });
        setOnlineStatus(onlineStatusMap);
        
      } catch (error) {
        console.error('Error fetching workshops', error);
      }
    };

    fetchUsers();
  }, []);
  useEffect(() => {
    const jwt = localStorage.getItem('token');
    if(selectedUserId) {
      const fetchWorkshop = async () => {
        const response = await fetch(`https://api.carwisi.com/workshop/v1/${selectedUserId}/workshop`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
      
        const workshop = await response.json();
        setNotificationMessage(t('exp'))
        const currentDate = moment();
        const expiration = moment(workshop?.subscriptionExpiresAt)
        const duration = moment.duration(expiration.diff(currentDate));
        const days = Math.floor(duration.asDays());
        setNotificationDate(t("days") + days + " " + t("day"))
      };
      fetchWorkshop()
    }
  }, [t, selectedUserId])

  const handleDeleteWorkshop = async (workshopId) => {
    try {
      const result = await Swal.fire({
        title: t('Are you sure?'),
        text: t("You won't be able to revert this!"),
        icon: 'warning',
        cancelButtonText: t('Cancel'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes, delete it!')
      });
      if(result.isConfirmed) {
        const jwt = localStorage.getItem('token');
        const response = await fetch(`https://api.carwisi.com/workshop/v1/${workshopId}`, {
          method: "DELETE",
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to delete workshop');
        }
        setTableData((prevWorkshops) => prevWorkshops.filter((workshop) => workshop._id !== workshopId));
  
        Swal.fire(t('Deleted!'), t('Your Workshop has been deleted.'), 'success');
      }
    } catch (error) {
      console.error('Error deleting workshop', error);
    }
  };

  const handleSendNotification = async () => {
    const jwt = localStorage.getItem('token');
    try {
      const response = await fetch(`https://api.carwisi.com/workshop/v1/notify-workshop/${selectedUserId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          message: notificationMessage,
          date: notificationDate,
        }),
      });

      if (response.ok) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          Toast.fire({
            icon: t('success'),
            title: t('You Have Send Notification Successfully'),
          })
          closeModal();
          localStorage.setItem('selectedUserIdWorkshop', selectedUserId);
        } else {
          const errorData = await response.json(); // Assuming the server sends a meaningful error response
          const errorMessage = errorData.message || 'Failed to send notification';
          console.error(errorMessage);
  
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
    
          Toast.fire({
            icon: 'error',
            title: errorMessage,
          });
        }
    } catch (error) {
      console.error('Error sending notification', error);
    }
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 8,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const check = (daysRemaining) => {
    const currentDate = moment();
    const expiration = moment(daysRemaining)
    const duration = moment.duration(expiration.diff(currentDate));

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const formattedDuration = `${days}:${hours}:${minutes}:${seconds}`;
    return days
  }

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
      <div class="relative flex items-center justify-between">
        <div class="text-xl font-bold text-navy-700 dark:text-white">
          {t('WorkShops')} <span><InputField
          type="text"
          placeholder={t('Search')}
          value={globalFilter || searchQuery || ''}
          onChange={(e) => {
            // Update the URL when the global filter changes
            const newSearchQuery = e.target.value;
            navigate(`?search=${newSearchQuery}`);
            tableInstance.setGlobalFilter(newSearchQuery);
          }}
  
        /></span>
        </div>
        <Link to={'/admin/create-workshop'}>
        <button className="linear mt-[53px] font-bold rounded-xl text-[#524442] bg-[#E2A91B] py-2 px-1.5 text-base transition duration-200  dark:text-white">
        {t('Create Workshop')}
        </button>
        </Link>
      </div>

      <div class="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="md:w-[60%] lg:w-[80%] xl:w-[95%]">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200  pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-900 dark:text-white">
                    {t(column.render("Header"))}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Avatar") {
                      data = (
                  <div className="relative me-4">
                    <img className="w-10 h-10 rounded-full" src={cell.value || require('../../../../assets/img/avatars/Logo-English.png')} alt="carwisi" />
                    <span className={`top-0 start-7 absolute w-3 h-3 border-2 border-white dark:border-gray-800 rounded-full ${onlineStatus[row.original._id] ? 'bg-green-500' : 'bg-red-500'}`}></span>
                  </div>
                      );
                    } 
                    if (cell.column.Header === "WorkShopName") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } 
                    else if (cell.column.Header === "PhoneNumber") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    else if (cell.column.Header === "WorkShopOwnerName") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }else if (cell.column.Header === "Status") {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className={`rounded-full text-xl`}>
                            {cell.value === "APPROVED" ? (
                              <MdCheckCircle className="text-green-500" />
                            ) : cell.value === "PENDING" ? (
                              <MdOutlineError className="text-red-500" />
                            ) : cell.value === "REFUSED" ? (
                              <MdCancel className="text-orange-500" />
                            ) : null}
                          </div>
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "Subscription") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }else if (cell.column.Header === "DaysRemaining") {
                      const textColorClass = check(cell.value) < 5 ? "text-red-500" : "text-navy-700 dark:text-white";
                      data = (
                        <p className={`text-sm pl-8 font-bold ${textColorClass}`}>
                          {check(cell.value)}
                        </p>
                      );
                    }else if (cell.column.id === "Actions") {
                      data = (
                        <div className="flex  gap-3 text-left">
                          <Link to={`/admin/update-workshop/${row.original._id}`}>
                          <button className="h-6 w-6 bg-blue-500 rounded-md flex item-center justify-center">
                            <FaEdit className="h-4 w-4 mt-1 text-white" />
                          </button>
                          </Link>
                          <button className="h-6 w-6 bg-red-500 rounded-md flex item-center justify-center"
                          onClick={() => handleDeleteWorkshop(row.original._id)}
                          >
                            <FaTrash className="h-4 w-4 mt-1 text-white" />
                          </button>
                          {row.original.status !== "APPROVED" && (
                                                      <Link to={`/admin/view-workshop/${row.original._id}`}>
                          <button className="h-6 w-6 bg-green-500 rounded-md flex item-center justify-center">
                            <FaEye className="h-4 w-4 mt-1 text-white" />
                          </button>
                          </Link>
                          )}
                          <button className="h-6 w-6 bg-blue-500 rounded-md flex item-center justify-center"
                          onClick={() => openModal(row.original._id)}
                          >
                          <MdNotificationAdd className="h-4 w-4 mt-1 text-white" />
                          </button>
                        </div>
                      );
                    }
                    return (
                      <td
                        className="pt-[10px] pb-[17px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Notification Details"
        className="fixed inset-0 z-50 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black"
      >
        <div className="bg-white dark:bg-navy-800 w-96 p-6 shadow-2xl rounded-lg">
        <h2 className='text-2xl font-bold mb-4'>{t('Notifications')}</h2>
          <InputField
            type="text"
            value={notificationMessage}
            label={t('Message')}
            onChange={(e) => setNotificationMessage(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
          />
          <InputField
            type="text"
            value={notificationDate}
            label={t('Date')}
            onChange={(e) => setNotificationDate(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
          />
    <div className="flex justify-between mt-4">
    {localStorage.getItem('selectedUserIdWorkshop') === selectedUserId ? (
        <><div></div></>
      ) : (
              <button
              className="px-4 py-2 bg-blue-500 flex items-center justify-center gap-3 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200 active:bg-blue-800"
              onClick={handleSendNotification}
            >
              <span><MdNotificationAdd /></span> {t('Send Notification')}
            </button>
      )}
      <button
        className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-gray-700 dark:text-white hover:border-gray-400 dark:hover:border-gray-500 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 active:border-gray-500 dark:active:border-gray-700"
        onClick={closeModal}
      >
        {t('Close')}
      </button>
    </div>
          </div>
      </Modal>
        <div className="mt-4 flex justify-end items-center">
        <button
          onClick={() => tableInstance.previousPage()}
          disabled={!tableInstance.canPreviousPage}
          className="mr-2 cursor-pointer"
        >
          <FaAngleLeft size={20} />
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
          </strong>{' '}
        </span>
        <button
          onClick={() => tableInstance.nextPage()}
          disabled={!tableInstance.canNextPage}
          className="ml-2 cursor-pointer"
        >
          <FaAngleRight size={20} />
        </button>
      </div>
      </div>
    </Card>
  );
};

export default ComplexTable;
