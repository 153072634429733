import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ProtectedRoute from "auth/privateRoute";
import UpdateWorkshop from "views/admin/pages/update-workshop";
import UpdateUser from "views/admin/pages/update-user";
import CreateUser from "views/admin/pages/create-user";
import CreateWorkshop from "views/admin/pages/create-workshop";
import ViewWorkshop from "views/admin/pages/view-workshop";

const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<ProtectedRoute><AdminLayout /></ProtectedRoute>} />
      <Route path="update-workshop/:id" element={<ProtectedRoute><UpdateWorkshop /></ProtectedRoute>} />
      <Route path="view-workshop/:id" element={<ProtectedRoute><ViewWorkshop /></ProtectedRoute>} />
      <Route path="update-user/:id" element={<ProtectedRoute><UpdateUser /></ProtectedRoute>} />
      <Route path="create-user/" element={<ProtectedRoute><CreateUser /></ProtectedRoute>} />
      <Route path="create-workshop/" element={<ProtectedRoute><CreateWorkshop /></ProtectedRoute>} />
      <Route path="/" element={<ProtectedRoute><Navigate to="/admin" replace /></ProtectedRoute>} />
    </Routes>
    </QueryClientProvider>
  );
};

export default App;
