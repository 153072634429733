import Card from 'components/card'
import InputField from 'components/fields/InputField'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTrash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import i18n from 'i18next';


const data = [
    "غسيل السيارات",
    "فلتر الهواء",
    "تغيير الزيت",
    "تعبئة البنزين",
    "الفحوصات السيارة",
    "تنظيف السيارة",
    "استبدال فلتر الكابينة",
    "ضبط المحرك",
    "تنظيف نظام الوقود",
    "تدوير الإطارات",
    "خدمة الفرامل",
    "فحص البطارية",
    "تنظيف السائل التبريد",
    "استبدال شمعات الإشعال",
    "محاذاة العجلات"
  ];

const Services = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [services, setServices] = useState("")    
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [error, setError] = useState(null)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [loadingServices, setLoadingServices] = useState(false);
  
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setInputValue(inputValue);
        const isServiceAvailable = data.some(service =>
            typeof service === 'string' && service.toLowerCase().includes(inputValue.toLowerCase())
          );
        setButtonDisabled(!inputValue || !isServiceAvailable);
        const filteredSuggestions = data.filter(service =>
          typeof service === 'string' && service.toLowerCase().includes(inputValue.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
      };
  
    const handleSelectSuggestion = (suggestion) => {
      setInputValue(suggestion);
      setSuggestions([]);
    };
    useEffect(() => {
      const jwt = localStorage.getItem('token');
      const currentLanguage = i18n.language || 'en';
      const fetchPaymentStatus = async () => {
        try {
          setLoadingServices(true);
          const response = await fetch(`https://api.carwisi.com/services/v1/find-all-services?lang=${currentLanguage}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${jwt}`,
            },
          });
    
          if (!response.ok) {
            throw new Error('Failed to fetch Services ');
          }
    
          const result = await response.json();
          const formattedResult = result.map(service => ({
            ...service,
            name: service.name.replace('message.messageService.', '')
          }));
    
          console.log(formattedResult);
          setServices(formattedResult);
        } catch (error) {
          console.error('Error fetching services', error);
        } finally {
          setLoadingServices(false);
        }
      };
    
      fetchPaymentStatus();
    }, []);
    

      const handleDeleteService = async (serviceId) => {
        try {
            const result = await Swal.fire({
                title: t('Are you sure?'),
                text: t("You won't be able to revert this!"),
                icon: 'warning',
                cancelButtonText: t('Cancel'),
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('Yes, delete it!')
              });
            if(result.isConfirmed) {
                const jwt = localStorage.getItem('token');
                const response = await fetch(`https://api.carwisi.com/services/v1/delete/${serviceId}`, {
                  method: 'DELETE',
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                  },
                });
          
                if (!response.ok) {
                  throw new Error('Failed to delete Services');
                }
          
                Swal.fire(t('Deleted!'), t('Your Services has been deleted.'), 'success');
            }
            window.location.reload();
        } catch (error) {
          console.error('Error deleting workshop', error);
          Swal.fire(t('Error'), t('Failed to delete the user.'), 'error');
        }
      };

      const handleCreateService = async () => {
        const jwt = localStorage.getItem('token');
        try {
          setLoading(true)
          if (!data.includes(inputValue.toLowerCase())) {
            setError(`Service "${inputValue}" is not available.`);
            return; // Exit function if the service is not available
          }
          const response = await fetch('https://api.carwisi.com/services/v1/create-service', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${jwt}`,
            },
            body: JSON.stringify({ name: inputValue }),
          });
    
          if (!response.ok) {
            const errorData = await response.json()
            throw new Error(errorData.message);
          }

          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
          });
      
          Toast.fire({
            icon: 'success',
            title: t('You Have Add Service Successfully'),
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
            setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  return (
    <div className='flex flex-col items-center m-12 h-screen'>
        <h1 class="text-xl mt-4 font-bold text-navy-700 dark:text-white">{t('Services')}</h1>
        <Card extra={'mt-10 mb-16 p-4 flex h-[50%] md:h-[80%] w-[100%] md:w-[60%] sm:overflow-x-auto px-2 md:px-0 lg:mb-10 '}>
        <div className="mb-4 flex items-center gap-3">
        {error && (
        <div className="mt-4 w-full">
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  {error}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        </div>
        <div className='flex flex-col md:flex-row items-center justify-center gap-2'>
            <InputField
            variant="servies"
            extra="mb-3 w-[100%] md:w-[70%]"
            label={t('Services')}
            value={inputValue}
            placeholder={t("Search services...")}
            onChange={handleInputChange}
            />
                    <button
          type="submit"
          className={`linear py-2 px-1 text-sm mt-4 rounded-xl ${buttonDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#E2A91B] text-white'} text-base font-medium transition duration-200 dark:text-white`}
          onClick={handleCreateService}
          disabled={buttonDisabled}
        >
                    {loading ? (<div role="status" className="flex items-center justify-center">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#524442]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>) : t('Add Service')}
        </button>
            </div>
            <div>
  {loading && (
    <div className="flex items-center justify-center absolute inset-0 bg-gray-100 bg-opacity-50 z-50">
      <svg className="animate-spin h-10 w-10 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V2.5a.5.5 0 011 0V4a8 8 0 01-8 8z"></path>
      </svg>
    </div>
  )}
  {inputValue && suggestions.length > 0 && !loading && (
    <ul className="absolute z-10 mt-2 w-full bg-white rounded-lg shadow-md border border-gray-200 divide-y divide-gray-200">
      {suggestions.map((suggestion, index) => (
        <li
          key={index}
          className="px-4 py-3 cursor-pointer hover:bg-gray-100 flex items-center justify-between"
          onClick={() => handleSelectSuggestion(suggestion)}
        >
          <span className="text-sm text-gray-800 font-bold">{suggestion}</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
  <line x1="12" y1="5" x2="12" y2="19" />
  <line x1="5" y1="12" x2="19" y2="12" />
</svg>
        </li>
      ))}
    </ul>
  )}
</div>
<div className="mt-4 overflow-x-auto">
  {loadingServices ? (
    <div className="flex items-center justify-center">
      {/* Your loading indicator */}
      <svg className="animate-spin h-5 w-5 mr-3 text-gray-600 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.418 3.582 8 8 8v-4zM20 12a8 8 0 01-8 8v4c4.418 0 8-3.582 8-8h-4zm-2-5.291A7.962 7.962 0 0120 12h4c0-4.418-3.582-8-8-8v4z"></path>
      </svg>
      <span>Loading...</span>
    </div>
  ) : (
    <>
      {services && services.length > 0 && services?.map((service) => (
        <div key={service._id} className="flex items-center justify-between p-2 border-b border-gray-200 dark:border-gray-600">
          <div>{service.name}</div>
          <button className="h-6 w-6 bg-red-500 rounded-md flex item-center justify-center"
            onClick={() => handleDeleteService(service._id)}
          >
            <FaTrash className="h-4 w-4 mt-1 text-white" />
          </button>
        </div>
      ))}
    </>
  )}
</div>
        </Card>
    </div>
  )
}

export default Services