import React from 'react'
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useQuery } from 'react-query';
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleLeft, FaAngleRight, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useUserInfo } from 'auth/userInfo';
import Swal from 'sweetalert2';
import InputField from 'components/fields/InputField';


const UserTable = (props) => {
    const { columnsData } = props;
    const { t } = useTranslation()
    const [tableData, setTableData] = useState([])
  const {data: loggedUser, isLoading, isError} = useUserInfo()
    //const { data: tableData, error, isLoading } = useQuery('workshops', fetchWorkshops);
  
    useEffect(() => {
      const jwt = localStorage.getItem('token');
      const fetchUsers = async () => {
        try {
          const response = await fetch('https://api.carwisi.com/user/v1/all_users?fields=username,fullName', {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${jwt}`,
            },
          });
          if (!response.ok) {
            throw new Error('Failed to fetch workshop');
          }
          const workshopData = await response.json();
          setTableData(workshopData);
        } catch (error) {
          console.error('Error fetching workshops', error);
        }
      };
  
      fetchUsers();
    }, []);

    const handleDeleteUser = async (userId) => {
        try {
            const result = await Swal.fire({
                title: t('Are you sure?'),
                text: t("You won't be able to revert this!"),
                icon: 'warning',
                cancelButtonText: t('Cancel'),
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('Yes, delete it!')
              });
            if(result.isConfirmed) {
                const jwt = localStorage.getItem('token');
                const response = await fetch(`https://api.carwisi.com/user/v1/${userId}`, {
                  method: 'DELETE',
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                  },
                });
          
                if (!response.ok) {
                  throw new Error('Failed to delete workshop');
                }
          
                setTableData((prevWorkshops) =>
                  prevWorkshops.filter((user) => user._id !== userId)
                );
          
                Swal.fire(t('Deleted!'), t('Your User has been deleted.'), 'success');
            }
        } catch (error) {
          console.error('Error deleting workshop', error);
          Swal.fire(t('Error'), t('Failed to delete the user.'), 'error');
        }
      };
  
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
  
    const tableInstance = useTable(
        {
          columns,
          data,
          initialState: {
            pageSize: 8,
          },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
      );
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize, globalFilter },
      } = tableInstance;
    return (
      <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
        <div class="relative flex items-center justify-between">
          <div class="text-xl font-bold text-navy-700 dark:text-white">
            {t('Users')} <span><InputField
          type="text"
          placeholder={t('Search')}
          value={globalFilter || ''}
          onChange={(e) => tableInstance.setGlobalFilter(e.target.value)}
        /></span>
          </div>
          <Link to={'/admin/create-user'}>
          <button className="linear mt-[53px] font-bold rounded-xl text-[#524442] bg-[#E2A91B] py-2 px-1.5 text-base transition duration-200  dark:text-white">
            {t('Create User')}
        </button>
          </Link>
        </div>
  
        <div class="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      className="border-b border-gray-200  pb-[10px] text-start dark:!border-navy-700"
                      >
                      <p className="text-xs tracking-wide text-gray-900 dark:text-white">
                      {t(column.render("Header"))}
                      </p>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "Avatar") {
                        data = (
                          <img
                          className="h-10 w-10 rounded-full"
                          src={cell.value || require('../../../../assets/img/avatars/Logo-English.png')}
                          alt="carwisi-xx"
                          />
                        );
                      } 
                      if (cell.column.Header === "FullName") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      } 
                      else if (cell.column.Header === "Email") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      }else if (cell.column.Header === "Role") {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              {cell.value === "ADMIN" ? (
                                <MdCheckCircle className="text-green-500" />
                              ) : cell.value === "ROLE" ? (
                                <MdCancel className="text-red-500" />
                              ) : null}
                            </div>
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          </div>
                        );
                      }else if (cell.column.id === "Actions") {
                        data = (
                          <div className="flex  gap-3 text-left">
                            <Link to={`/admin/update-user/${row.original._id}`}>
                            <button className="h-6 w-6 bg-blue-500 rounded-md flex item-center justify-center">
                              <FaEdit className="h-4 w-4 mt-1 text-white" />
                            </button>
                            </Link>
                            {loggedUser && loggedUser._id !== row.original._id && (
                            <button className="h-6 w-6 bg-red-500 rounded-md flex item-center justify-center"
                            onClick={() => handleDeleteUser(row.original._id)}
                            >
                            <FaTrash className="h-4 w-4 mt-1 text-white" />
                            </button>
                            )}
                          </div>
                        );
                      }
                      return (
                        <td
                          className="pt-[14px] pb-[18px] sm:text-[14px]"
                          {...cell.getCellProps()}
                          key={index}
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="mt-4 flex justify-end items-center">
        <button
          onClick={() => tableInstance.previousPage()}
          disabled={!tableInstance.canPreviousPage}
          className="mr-2 cursor-pointer"
        >
          <FaAngleLeft size={20} />
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
          </strong>{' '}
        </span>
        <button
          onClick={() => tableInstance.nextPage()}
          disabled={!tableInstance.canNextPage}
          className="ml-2 cursor-pointer"
        >
          <FaAngleRight size={20} />
        </button>
      </div>
        </div>
      </Card>
    );
  };

export default UserTable