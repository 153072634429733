import { IoDocuments } from "react-icons/io5";
import { MdBarChart } from "react-icons/md";
import Widget from "components/widget/Widget";
import { GiMechanicGarage } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import { RiRoadsterFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import Tables from "../tables";
import { useEffect, useState } from "react";
import { CiCreditCardOff } from "react-icons/ci";
import Card from "components/card";

const Dashboard = () => {
  const { t } = useTranslation()
  const [carCount, setCarCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [workshopCount, setWorkshopCount] = useState(0);
  const [freeTrialWorkshopCount, setFreeTrialWorkshopCount] = useState(0);
  const [paidWorkshopCount, setPaidWorkshopCount] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      const jwt = localStorage.getItem('token');
      try {
        const carResponse = await fetch('https://api.carwisi.com/cars/v1/count-clients', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const userDataResponse = await fetch('https://api.carwisi.com/user/v1/count-user', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const workshopResponse = await fetch('https://api.carwisi.com/workshop/v1/count-workshop', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        const carData = await carResponse.json();
        const userData = await userDataResponse.json();
        const workshopData = await workshopResponse.json();

        setCarCount(carData || 0);
        setUserCount(userData || 0);
        setWorkshopCount(workshopData || 0);

        // Fetch all workshops
        const allWorkshopsResponse = await fetch('https://api.carwisi.com/workshop/v1/all_workshop', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const allWorkshopsData = await allWorkshopsResponse.json();


        const countFreeTrialWorkshops = allWorkshopsData.filter((workshop) => workshop.subscriptionStatus === 'FREE_TRIAL').length;
        const countPaidWorkshops = allWorkshopsData.filter((workshop) => workshop.subscriptionStatus === 'PAID_PLAN').length;
        setFreeTrialWorkshopCount(countFreeTrialWorkshops || 0);
        setPaidWorkshopCount(countPaidWorkshops || 0);
      } catch (error) {
        console.error('Error fetching counts', error);
      }
    };

    fetchCounts();
  }, []);
  return (
    <div>
      {/* Card widget */}

      <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
      <Card extra="!flex-row flex-grow items-center rounded-[20px] mt-5">
      <div className="ml-[18px] flex h-[110px] w-auto flex-row items-center">
        <div className="rounded-full bg-[#524442] p-3 dark:bg-navy-700">
          <span className="flex items-center text-[#E2A91B] dark:text-white">
          <GiMechanicGarage className="h-7 w-7" />
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 gap-1 flex flex-col items-start justify-center">
        <p className="font-dm text-md font-medium text-gray-600">{t("total_WorkShops")}<span className="text-md font-bold mx-3.5 text-navy-700 dark:text-white">
        {workshopCount}
        </span></p>
        <p className="font-dm text-md font-medium text-gray-600">{t("Free_Trial")}<span className="text-md font-bold mx-3.5 text-navy-700 dark:text-white">
        {freeTrialWorkshopCount}
        </span></p>
        <p className="font-dm text-md font-medium text-gray-600">{t("Paid_Plan")}<span className="text-md font-bold mx-3.5 text-navy-700 dark:text-white">
        {paidWorkshopCount}
        </span></p>
      </div>
    </Card>
        <Widget
          icon={<FaUsers className="h-6 w-6" />}
          title={t("Users")}
          subtitle={userCount}
        />
        <Widget
          icon={<RiRoadsterFill className="h-7 w-7" />}
          title={t("Cars")}
          subtitle={carCount}
        />
      </div>

      <div className="mt-3 grid grid-cols-1 gap-2 md:grid-cols-1">
        <Tables/>
      </div>
    </div>
  );
};

export default Dashboard;
