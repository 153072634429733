import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import Card from 'components/card';
import Swal from 'sweetalert2'; 
import { useNavigate } from 'react-router-dom'
import i18n from 'i18next';

// Define validation schema using Yup

const CreateWorkshop = () => {
    const { t } = useTranslation()
    const validationSchema = Yup.object({
      workShopName: Yup.string().required(t('Workshop Name is required')),
      workShopOwnerName: Yup.string().required(t('Workshop Owner Name is required')),
      address: Yup.string().required(t('Address is required')),
      phoneNumber: Yup.string().required(t('Phone Number is required')),
      email: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
      password: Yup.string().min(8, t('Password must be at least 8 characters')).required(t('Password is required')),
      confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('Passwords must match'))
      .required(t('Confirm Password is required')),
      subscriptionStatus: Yup.string().required(t('Subscription Status is required')),
      expiration: Yup.number().required(t('Expiration Days is Required'))
    });
    const navigate = useNavigate()
    const [authError, setAuthError] = useState(null)
    const [workShopName, setWorkShopName] = useState('')
    const [workShopOwnerName, setWorkShopOwnerName] = useState('')
    const [address, setAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [subscriptionStatus, setSubscriptionStatus] = useState('')
    const [expiration, setExpiration] = useState(0)
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
      initialValues: {
          workshop: {
              workShopName: '',
              workShopOwnerName: '',
              address: '',
              phoneNumber: '',
              email: '',
              password: '',
              subscriptionStatus: '',
              expiration: 0,
          }
      },
      validationSchema,
      onSubmit: (values) => {
      },
    });
    const handleCreateWorkshop = async () => {
    const exp = parseInt(expiration, 10)
        const jwt = localStorage.getItem('token');
    const currentLanguage = i18n.language || 'en';
        try {
          setLoading(true)
          const response = await fetch(`https://api.carwisi.com/workshop/v1/create-workshop-admin?lang=${currentLanguage}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${jwt}`,
            },
            body: JSON.stringify({workShopName, workShopOwnerName, address, phoneNumber, email, password, subscriptionStatus, expiration: exp}),
          });
    
          if (!response.ok) {
            const errorData = await response.json()
            throw new Error(errorData.message);
          }
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
          });
      
          Toast.fire({
            icon: 'success',
            title: t('You Have Create Workshop Successfully'),
          }).then(() => {
            navigate('/admin/workshop')
          });
        } catch (error) {
          setAuthError(error.message);
        } finally {
          setLoading(false);
        }
      };

  return (
    <Card extra={'mt-24 mb-16 p-4 flex h-full w-[60%] mx-auto sm:overflow-x-auto items-center justify-center px-2 md:mx-auto md:px-0 lg:mb-10 lg:items-center lg:justify-center'}>
      <div class="relative flex flex-col items-center justify-center">
        <div class="text-xl mt-4 font-bold text-navy-700 dark:text-white">
          {t('Create Workshops')}
        </div>
        <div className="my-3 gap-2 ">
        {authError && (
        <div className="mt-4 w-full">
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  {authError}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        </div>
      </div>
    <div className='mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center'>
        <div className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
      <form onSubmit={formik.handleSubmit}>
        {/* Workshop Name */}
        <InputField
          variant="auth"
          extra="mb-3"
          label={t('WorkShopName')}
          id="workShopName"
          type="text"
          value={workShopName}
          onBlur={formik.handleBlur}
          onChange={(e) => setWorkShopName(e.target.value)}
          className={`mt-1 p-2 w-full border rounded-md ${
            formik.touched.workShopName && formik.errors.workShopName ? 'border-red-500' : 'border-gray-300'
          }`}
        />
        {formik.touched.workShopName && formik.errors.workShopName && (
          <div className="text-red-500 text-sm">{formik.errors.workShopName}</div>
        )}

        {/* Workshop Owner Name */}
        <InputField
          variant="auth"
          extra="mb-3"
          label={t('workShopOwnerName')}
          id="workShopOwnerName"
          type="text"
          value={workShopOwnerName}
          onBlur={formik.handleBlur}
          onChange={(e) => setWorkShopOwnerName(e.target.value)}
          className={`mt-1 p-2 w-full border rounded-md ${
            formik.touched.workShopOwnerName && formik.errors.workShopOwnerName
              ? 'border-red-500'
              : 'border-gray-300'
          }`}
        />
        {formik.touched.workShopOwnerName && formik.errors.workShopOwnerName && (
          <div className="text-red-500 text-sm">{formik.errors.workShopOwnerName}</div>
        )}

        {/* Address */}
        <InputField
          variant="auth"
          extra="mb-3"
          label={t('Address')}
          id="address"
          type="text"
          value={address}
          onBlur={formik.handleBlur}
          onChange={(e) => setAddress(e.target.value)}
          className={`mt-1 p-2 w-full border rounded-md ${
            formik.touched.address && formik.errors.address ? 'border-red-500' : 'border-gray-300'
          }`}
        />
        {formik.touched.address && formik.errors.address && (
          <div className="text-red-500 text-sm">{formik.errors.address}</div>
        )}

        {/* Phone Number */}
        <InputField
          variant="auth"
          extra="mb-3"
          label={t('PhoneNumber')}
          id="phoneNumber"
          type="text"
          value={phoneNumber}
          onBlur={formik.handleBlur}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className={`mt-1 p-2 w-full border rounded-md ${
            formik.touched.phoneNumber && formik.errors.phoneNumber
              ? 'border-red-500'
              : 'border-gray-300'
          }`}
        />
        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
          <div className="text-red-500 text-sm">{formik.errors.phoneNumber}</div>
        )}

        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label={t("Email")}
          placeholder="mail@example.com"
          id="email"
          type="email"
          value={email}
          onBlur={formik.handleBlur}
          onChange={(e) => setEmail(e.target.value)}
          className={`mt-1 p-2 w-full border rounded-md ${
            formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-300'
          }`}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="text-red-500 text-sm">{formik.errors.email}</div>
        )}

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label={t('Password')}
          placeholder="*******"
          id="password"
          type="password"
          value={password}
          onBlur={formik.handleBlur}
          onChange={(e) => setPassword(e.target.value)}
          className={`mt-1 p-2 w-full border rounded-md ${
            formik.touched.password && formik.errors.password ? 'border-red-500' : 'border-gray-300'
          }`}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="text-red-500 text-sm">{formik.errors.password}</div>
        )}

      <InputField
        variant="auth"
        extra="mb-3"
        label={t('ConfirmPassword')}
        placeholder="*******"
        id="confirmPassword"
        type="password"
        onBlur={formik.handleBlur}
        className={`mt-1 p-2 w-full border rounded-md ${
          formik.touched.confirmPassword && formik.errors.confirmPassword
            ? 'border-red-500'
            : 'border-gray-300'
        }`}
      />
      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
        <div className="text-red-500 text-sm">{formik.errors.confirmPassword}</div>
      )}

                          <InputField
                                variant="auth"
                                label={t('expiration')}
                                placeholder={t('expiration')}
                                id="expiration"
                                type="number"
                                value={expiration}
                                onBlur={formik.handleBlur}
                                onChange={(e) => setExpiration(e.target.value)}
                                className={`mt-1 p-2 w-full border rounded-md ${
                                  formik.touched.expiration && formik.errors.expiration
                                    ? 'border-red-500'
                                    : 'border-gray-300'
                                }`}
                              />
                              {formik.touched.expiration && formik.errors.expiration && (
                                <div className="text-red-500 text-sm">{formik.errors.expiration}</div>
                              )}


        {/* Subscription Status Dropdown */}
        <div className="mb-3">
          <label htmlFor="subscriptionStatus" className="block text-sm font-medium text-gray-700">
            {t("Subscription Status")}
          </label>
          <select
            id="subscriptionStatus"
            name={t("Subscription Status")}
            value={subscriptionStatus}
            onBlur={formik.handleBlur}
            onChange={(e) => setSubscriptionStatus(e.target.value)}
            className={`mt-1 p-2 w-full border rounded-md ${
              formik.touched.subscriptionStatus && formik.errors.subscriptionStatus
                ? 'border-red-500'
                : 'border-gray-300'
            }`}
          >
            <option value="" label={t("Select subscription status")} />
            <option value="FREE_TRIAL" label="FREE_TRIAL" />
            <option value="PAID_PLAN" label="PAID_PLAN" />
            <option value="GOLD_PLAN" label="GOLD_PLAN" />
          </select>
          {formik.touched.subscriptionStatus && formik.errors.subscriptionStatus && (
            <div className="text-red-500 text-sm">{formik.errors.subscriptionStatus}</div>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="linear mt-2 w-full rounded-xl bg-[#E2A91B] py-[12px] text-base font-medium text-white transition duration-200  dark:text-white"
          onClick={handleCreateWorkshop}
        >
          {loading ? (<div role="status" className="flex items-center justify-center">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#524442]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>) : t('Create Workshops')}
        </button>
      </form>
    </div>
      </div>
    </Card>
  );
};

export default CreateWorkshop;
