import Card from 'components/card';
import InputField from 'components/fields/InputField';
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FaAngleLeft, FaAngleRight, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from "react-table";
const CommentTable = (props) => {
  const { columnsData } = props;
    const [comments, setComments] = useState([]);

    useEffect(() => {
      const fetchComments = async () => {
      const jwt = localStorage.getItem('token');
        try {
          const response = await fetch('https://api.carwisi.com/comments/v1/get-comments', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${jwt}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch comments');
          }
  
          const commentsData = await response.json();
          setComments(commentsData);
        } catch (error) {
          console.error('Error fetching comments', error);
        }
      };
  
      fetchComments();
    }, []);

    const handleDeleteComment = async (id) => {
      try {
        const result = await Swal.fire({
          title: t('Are you sure?'),
          text: t("You won't be able to revert this!"),
          icon: 'warning',
          cancelButtonText: t('Cancel'),
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: t('Yes, delete it!')
        });
        if(result.isConfirmed) {
          const jwt = localStorage.getItem('token');
          const response = await fetch(`https://api.carwisi.com/comments/v1/delete-comments/${id}`, {
            method: "DELETE",
            headers: {
              'Authorization': `Bearer ${jwt}`,
            },
          });
    
          if (!response.ok) {
            throw new Error('Failed to delete workshop');
          }

          setComments((prevWorkshops) => prevWorkshops.filter((comment) => comment._id !== id));
    
          Swal.fire(t('Deleted!'), t('Your Comment has been deleted.'), 'success');
        }
      } catch (error) {
        console.error('Error deleting workshop', error);
      }
    };
  
    const { t } = useTranslation()

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => comments, [comments]);
  
    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: 5,
        },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      state: { pageIndex, pageSize, globalFilter },
    } = tableInstance;
  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
    <div class="relative flex items-center justify-between">
      <div class="text-xl font-bold text-navy-700 dark:text-white">
      {t('Comments List')} <span><InputField
        type="text"
        placeholder="Search..."
        value={globalFilter || ''}
        onChange={(e) => tableInstance.setGlobalFilter(e.target.value)}
      /></span>
      </div>
    </div>

    <div class="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  className="border-b border-gray-200 pr-20 pb-[10px] text-start dark:!border-navy-700"
                >
                  <p className="text-xs tracking-wide text-gray-900 dark:text-white">
                  {t(column.render("Header"))}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </p>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "ID") {
                    data = (
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {cell.value}
                      </p>
                    );
                  } 
                  else if (cell.column.Header === "Content") {
                    data = (
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {cell.value}
                      </p>
                    );
                  }
                  else if (cell.column.Header === "Email") {
                    data = (
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {row.original?.user?.email}
                      </p>
                    );
                  }else if (cell.column.id === "Actions") {
                    data = (
                      <div className="flex  gap-3 text-left">
                        <button className="h-6 w-6 bg-red-500 rounded-md flex item-center justify-center"
                        onClick={() => handleDeleteComment(row.original._id)}
                        >
                          <FaTrash className="h-4 w-4 mt-1 text-white" />
                        </button>
                      </div>
                    );
                  }
                  return (
                    <td
                      className="pt-[14px] pb-[18px] sm:text-[14px]"
                      {...cell.getCellProps()}
                      key={index}
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="mt-4 flex justify-end items-center">
      <button
        onClick={() => tableInstance.previousPage()}
        disabled={!tableInstance.canPreviousPage}
        className="mr-2 cursor-pointer"
      >
        <FaAngleLeft size={20} />
      </button>
      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
        </strong>{' '}
      </span>
      <button
        onClick={() => tableInstance.nextPage()}
        disabled={!tableInstance.canNextPage}
        className="ml-2 cursor-pointer"
      >
        <FaAngleRight size={20} />
      </button>
    </div>
    </div>
  </Card>
  )
}

export default CommentTable