import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
// Auth Imports
import SignIn from "views/auth/SignIn";
import RTLDefault from "views/rtl";

// Icon Imports
import {
  MdHome,
  MdPerson,
  MdRateReview
} from "react-icons/md";
import { GiMechanicGarage } from "react-icons/gi";
import { FaPhoneAlt, FaUsers } from "react-icons/fa";
import { TbDeviceMobileDollar } from "react-icons/tb";
import Users from "views/admin/tables/components/User";
import UpdateUser from "views/admin/pages/update-user";
import UpdateWorkshop from "views/admin/pages/update-workshop";
import CreateUser from "views/admin/pages/create-user";
import CreateWorkshop from "views/admin/pages/create-workshop";
import Tables from "views/admin/tables";
import ViewWorkshop from "views/admin/pages/view-workshop";
import { SiAuthelia } from "react-icons/si";
import { GrServices } from "react-icons/gr";
import Comment from "views/admin/tables/components/Comment";
import PaymentToggle from "views/admin/app/app-payment";
import Notifications from "views/admin/tables/components/Notifications";
import Contacts from "views/admin/contacts/contacts";
import Services from "views/admin/services/services";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "WorkShops",
    layout: "/admin",
    path: "workshop",
    icon: <GiMechanicGarage className="h-6 w-6" />,
    component: <Tables />,
    secondary: true,
  },
  {
    name: "Users",
    layout: "/admin",
    icon: <FaUsers className="h-6 w-6" />,
    path: "users",
    component: <Users />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Comments",
    layout: "/admin",
    path: "comments",
    icon: <MdRateReview className="h-6 w-6" />,
    component: <Comment />,
  },
  {
    name: "APP Payment",
    layout: "/admin",
    path: "app-payment",
    icon: <TbDeviceMobileDollar className="h-6 w-6" />,
    component: <PaymentToggle />,
  },
  {
    name: "User Authentication",
    layout: "/admin",
    path: "user-auth",
    icon: <SiAuthelia className="h-6 w-6" />,
    component: <Notifications />,
  },
  {
    name: "Services",
    layout: "/admin",
    path: "services",
    icon: <GrServices className="h-6 w-6" />,
    component: <Services />,
  },
  {
    name: "Contacts",
    layout: "/admin",
    path: "contacts",
    icon: <FaPhoneAlt className="h-5 w-5" />,
    component: <Contacts />,
  },
  {
    name: "Auth",
    layout: "/auth",
    path: "sign-in",
    component: <SignIn />,
  },

  {
    layout: "/admin",
    path: "update-workshop/:id",
    component: <UpdateWorkshop />,
  },
  {
    layout: "/admin",
    path: "update-user/:id",
    component: <UpdateUser />,
  },
  {
    layout: "/admin",
    path: "create-workshop",
    component: <CreateWorkshop />,
  },
  {
    layout: "/admin",
    path: "create-user",
    component: <CreateUser />,
  },
  {
    layout: "/admin",
    path: "view-workshop/:id",
    component: <ViewWorkshop />,
  },
];
export default routes;
