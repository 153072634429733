import { FaEdit, FaTrash, FaEye } from "react-icons/fa";

export const columnsDataDevelopment = [
  {
    Header: "Avatar",
    accessor: "avatar",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "WorkShopName",
    accessor: "workshopName",
  },
  {
    Header: "CarModel",
    accessor: "carModel",
  },
  {
    Header: "Inspection",
    accessor: "inspection",
  },
  {
    Header: "Car Number",
    accessor: "car_plate_number",
  },
  {
    Header: "DaysRemaining",
    accessor: "daysRemaining",
  },
  {
    Header: "Actions",
    accessor: "Actions",
  },
];

export const columnsDataCheck = [
  {
    Header: "ID",
    accessor: "_id",
  },
  {
    Header: "Content",
    accessor: "content",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Actions",
    accessor: "Actions",
  },
];

export const columnsDataColumns = [
  {
    Header: "Avatar",
    accessor: "avatar",
  },
  {
    Header: "FullName",
    accessor: "fullName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Actions",
    accessor: "Actions",
    Cell: ({ row }) => (
      <div className="flex justify-around">
        <button>
          <FaEdit />
        </button>
        <button>
          <FaTrash />
        </button>
        <button>
          <FaEye />
        </button>
      </div>
    ),
  },
];

export const columnsDataComplex = [
  {
    Header: "Avatar",
    accessor: "avatar",
  },
  {
    Header: "WorkShopName",
    accessor: "workShopName",
  },
  {
    Header: "PhoneNumber",
    accessor: "phoneNumber",
  },
  {
    Header: "WorkShopOwnerName",
    accessor: "workShopOwnerName",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Subscription",
    accessor: "subscriptionStatus",
  },
  {
    Header: "DaysRemaining",
    accessor: "subscriptionExpiresAt"
  },
    {
    Header: "Actions",
    accessor: "Actions",
    Cell: ({ row }) => (
      <div className="flex justify-around">
        <button>
          <FaEdit />
        </button>
        <button>
          <FaTrash />
        </button>
        <button>
          <FaEye />
        </button>
      </div>
    ),
  },
];
