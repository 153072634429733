/*eslint-disable*/
import React from "react";
export default function Footer() {
  return (
    <div className="flex w-full mt-3 flex-col items-center justify-center px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
          <div className="copyright text-center dark:text-white font-weight-bold text-xl-left text-muted">
           Copyright {new Date().getFullYear()} Carwisi © Developed By {" "}
            <a
              className="font-weight-bold ml-1 text-red-600"
              href="https://www.octadigital.io/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Octadigital
            </a>
          </div>
    </div>
  );
}
