import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { jwtDecode } from 'jwt-decode'

import axios from 'axios';
const API_BASE_URL = process.env.API_BASE_URL

const loginUser = async (email, password) => {
  const currentLanguage = i18n.language || 'en';

  try {
    const response = await axios.post(
      `http://api.carwisi.com:4000/auth/v1/user_signin?lang=${currentLanguage}`,
      { email, password },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const data = response.data;

    localStorage.setItem('token', data.accessToken);

    return data;
  } catch (error) {
    let errorMessage = 'Failed on sign in request';

    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    throw new Error(errorMessage);
  }
};


const logoutUser = () => {
    localStorage.removeItem('token');
  };
  
  const getAuthToken = () => {
    const token = localStorage.getItem('token');
  
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; 
  
      if (decodedToken.exp < currentTime) {
        logoutUser();
        return null;
      }
  
      return token;
    }
  
    return null;
  };
  
  export { loginUser, logoutUser, getAuthToken };