import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18N from './i18n/i18n'
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18N}>
    <App />
    </I18nextProvider>
  </BrowserRouter>
);
