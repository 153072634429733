import InputField from 'components/fields/InputField'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import Card from 'components/card';
import Swal from 'sweetalert2'; 
import { FaTrash } from 'react-icons/fa';

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    username: Yup.string().required('Username is required'),
    fullName:  Yup.string().required('Full Name is required'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    role: Yup.string().required('Role is required'),
  });

  const Upload = ({id}) => {
    const [avatar, setAvatar] = useState(null);
    const { t } = useTranslation()
    const navigate = useNavigate()
  
    const handleProfileImage = async () => {
      const jwt = localStorage.getItem('token');
  
      try {
        const formData = new FormData();
        formData.append('avatar', avatar);
  
        const response = await fetch(`https://api.carwisi.com/user/v1/user/images_profile_admin/${id}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          body: formData,
        });
  
        if (!response.ok) {
          const errorData = await response.json()
          throw new Error(errorData.message);
        }
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });
    
        Toast.fire({
          icon: 'success',
          title: t('You Have Update Profile Image Successfully'),
        }).then(() => {
          navigate('/admin/users')
        });
      } catch (error) {
        console.error('Error updating profile', error);
      }
    };
    return (
      <Card className="grid h-full w-full grid-cols-1 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
        <div class="col-span-5 h-full w-full rounded-xl bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6">
      <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
              <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
              </svg>
              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">{t('Upload Files')}</span></p>
              <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG</p>
          </div>
          <input id="dropzone-file" type="file" name="avatar" onChange={(event) => setAvatar(event.target.files[0])} class="hidden"  />
      </label>
      </div>
  
      <div className="col-span-5 flex h-full w-full flex-col justify-center overflow-hidden rounded-xl bg-white pl-3 pb-4 dark:!bg-navy-800">
        <button
            onClick={handleProfileImage}
            className="linear mt-4 flex items-center justify-center rounded-xl bg-brand-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            {t('Upload Image Now')}
          </button>
      </div>
    </Card>
    );
  };

const UpdateUser = () => {
    const { id } = useParams();
    const { t } = useTranslation()
    const [authError, setAuthError] = useState(null)
  const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        username: "",
        email: "",
        password: "",
        fullName: "",
        phoneNumber: "",
        role: "",
      });
    const formik = useFormik({
        initialValues: {
            user: {
                email: user.email || '',
                username: user.username || '',
                password: user.password || '',
                fullName: user.fullName || "",
                phoneNumber: user.phoneNumber || '',
                role: user.role || '',
            }
        },
        validationSchema,
        onSubmit: (values) => {
          // Handle form submission
          console.log('Form submitted with values:', values);
        },
      })

  const navigate = useNavigate()

      useEffect(() => {
        const jwt = localStorage.getItem('token');
        const fetchUser = async () => {
          try {
            const response = await fetch(`https://api.carwisi.com/user/v1/user/${id}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`,
              },
            });
    
            if (!response.ok) {
              throw new Error("Failed to fetch user");
            }
    
            const userData = await response.json();
            setUser(userData);
          } catch (error) {
            console.error("Error fetching user", error);
          }
        };
    
        fetchUser();
      }, [id]);

      const handleDeleteUser = async (userId) => {
        try {
            const result = await Swal.fire({
                title: t('Are you sure?'),
                text: t("You won't be able to revert this!"),
                icon: 'warning',
                cancelButtonText: t('Cancel'),
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('Yes, delete it!')
              });
            if(result.isConfirmed) {
                const jwt = localStorage.getItem('token');
                const response = await fetch(`https://api.carwisi.com/user/v1/${userId}`, {
                  method: 'DELETE',
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                  },
                });
          
                if (!response.ok) {
                  throw new Error('Failed to delete workshop');
                }
          
                setUser((prevWorkshops) =>
                  prevWorkshops.filter((user) => user._id !== userId)
                );
          
                Swal.fire(t('Deleted!'), t('Your User has been deleted.'), 'success');
                navigate('/admin/users')
            }
        } catch (error) {
          console.error('Error deleting workshop', error);
          Swal.fire(t('Error'), t('Failed to delete the user.'), 'error');
        }
      };
    
      const handleUpdateProfile = async () => {
        const jwt = localStorage.getItem('token');
        try {
          setLoading(true)
          const response = await fetch(`https://api.carwisi.com/user/v1/update-user/${id}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`,
            },
            body: JSON.stringify(user),
          });
    
          if (!response.ok) {
            const errorData = await response.json()
            throw new Error(errorData.message);
          }
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
          });
      
          Toast.fire({
            icon: 'success',
            title: t('You Have Update User Successfully'),
          }).then(() => {
            navigate('/admin/users')
          });
          console.log("User profile updated successfully");
        } catch (error) {
          setAuthError(error.message);
        } finally {
          setLoading(false);
        }
      };
  return (
    <Card extra={'mt-24 mb-16 p-4 flex h-full w-[60%] mx-auto sm:overflow-x-auto items-center justify-center px-2 md:mx-auto md:px-0 lg:mb-10 lg:items-center lg:justify-center'}>
      <div className='flex items-end justify-end text-right'>
      <button className="h-6 w-6 bg-red-500 rounded-md flex item-center justify-center"
                            onClick={() => handleDeleteUser(user._id)}
                            >
                            <FaTrash className="h-4 w-4 mt-1 text-white" />
                            </button>
      </div>
        <div class="relative flex flex-col items-center justify-center">
        <div class="text-xl mb-4 font-bold text-navy-700 dark:text-white">
          {t('Users')}
        </div>
        <div className="text-xl font-bold text-navy-700 dark:text-white">
        {user.email}
        </div>
        <div className="my-4 gap-3">
        {authError && (
        <div className="mt-4 w-full">
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  {authError}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        </div>
      </div>
      <div className='mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center'>
      <div className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
    <form onSubmit={formik.handleSubmit}>
      {/* Email */}
      <InputField
        variant="auth"
        extra="mb-3"
        label={t('Email')}
        placeholder="mail@example.com"
        id="email"
        type="email"
        onBlur={formik.handleBlur}
        value={user.email}
        onChange={(e) => setUser({ ...user, email: e.target.value })}
        className={`mt-1 p-2 w-full border rounded-md ${
          formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-300'
        }`}
      />
      {formik.touched.email && formik.errors.email && (
        <div className="text-red-500 text-sm">{formik.errors.email}</div>
      )}

<InputField
        variant="auth"
        extra="mb-3"
        label={t('fullName')}
        id="fullname"
        type="text"
        value={user.fullName}
        onBlur={formik.handleBlur}
        onChange={(e) => setUser({ ...user, fullName: e.target.value })}
        className={`mt-1 p-2 w-full border rounded-md ${
          formik.touched.fullName && formik.errors.fullName ? 'border-red-500' : 'border-gray-300'
        }`}
      />
      {formik.touched.fullName && formik.errors.fullName && (
        <div className="text-red-500 text-sm">{formik.errors.fullName}</div>
      )}

      {/* Username */}
      <InputField
        variant="auth"
        extra="mb-3"
        label={t('Username')}
        id="username"
        type="text"
        value={user.username}
        onBlur={formik.handleBlur}
        onChange={(e) => setUser({ ...user, username: e.target.value })}
        className={`mt-1 p-2 w-full border rounded-md ${
          formik.touched.username && formik.errors.username ? 'border-red-500' : 'border-gray-300'
        }`}
      />
      {formik.touched.username && formik.errors.username && (
        <div className="text-red-500 text-sm">{formik.errors.username}</div>
      )}

      {/* Password */}
      <InputField
        variant="auth"
        extra="mb-3"
        label={t('Password')}
        placeholder="*********"
        id="password"
        onBlur={formik.handleBlur}
        type="password"
        onChange={(e) => setUser({ ...user, password: e.target.value })}
        className={`mt-1 p-2 w-full border rounded-md ${
          formik.touched.password && formik.errors.password ? 'border-red-500' : 'border-gray-300'
        }`}
      />
      {formik.touched.password && formik.errors.password && (
        <div className="text-red-500 text-sm">{formik.errors.password}</div>
      )}

      {/* Confirm Password */}
      <InputField
        variant="auth"
        extra="mb-3"
        label={t("ConfirmPassword")}
        placeholder="*********"
        id="confirmPassword"
        onBlur={formik.handleBlur}
        type="password"
        className={`mt-1 p-2 w-full border rounded-md ${
          formik.touched.confirmPassword && formik.errors.confirmPassword
            ? 'border-red-500'
            : 'border-gray-300'
        }`}
      />
      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
        <div className="text-red-500 text-sm">{formik.errors.confirmPassword}</div>
      )}

      {/* Phone Number */}
      <InputField
        variant="auth"
        extra="mb-3"
        label={t('phoneNumber')}
        id="phoneNumber"
        type="text"
        onBlur={formik.handleBlur}
        value={user.phoneNumber}
        onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })}
        className={`mt-1 p-2 w-full border rounded-md ${
          formik.touched.phoneNumber && formik.errors.phoneNumber
            ? 'border-red-500'
            : 'border-gray-300'
        }`}
      />
      {formik.touched.phoneNumber && formik.errors.phoneNumber && (
        <div className="text-red-500 text-sm">{formik.errors.phoneNumber}</div>
      )}

      {/* Role Dropdown */}
      <div className="mb-3">
        <label htmlFor="role" className="block text-sm font-medium text-gray-700">
          {t("Select Role")}
        </label>
        <select
          id="role"
          name={t('role')}
          onChange={(e) => setUser({ ...user, role: e.target.value })}
          onBlur={formik.handleBlur}
          value={user.role}
          className={`mt-1 p-2 w-full border rounded-md ${
            formik.touched.role && formik.errors.role ? 'border-red-500' : 'border-gray-300'
          }`}
        >
          <option value={user.role} label={t("Select Role")} />
          <option value="ADMIN" label="ADMIN" />
          <option value="USER" label="USER" />
        </select>
        {formik.touched.role && formik.errors.role && (
          <div className="text-red-500 text-sm">{formik.errors.role}</div>
        )}
      </div>

      <div>
        <Upload id={id}/>
        </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="linear mt-2 w-full rounded-xl bg-[#E2A91B] py-[12px] text-base font-medium text-white transition duration-200  dark:text-white"
        onClick={handleUpdateProfile}
      >
                  {loading ? (<div role="status" className="flex items-center justify-center">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#524442]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>) : t('Update Profile')}
      </button>
    </form>
  </div>
      </div>
    </Card>
  )
}

export default UpdateUser