import { useQuery } from 'react-query';
import { getAuthToken } from './auth';

const getUserInfo = async () => {
  const token = getAuthToken();

  if (!token) {
    throw new Error('User not authenticated');
  }

  const response = await fetch('https://api.carwisi.com/user/v1/me', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user info');
  }

  const data = await response.json();
  return data;
};

const useUserInfo = () => {
  return useQuery('userInfo', getUserInfo);
};

export { useUserInfo };