import React, {useEffect, useState} from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiPassPendingLine, RiSunFill } from "react-icons/ri";
import avatar from "assets/img/avatars/avatar4.png";
import { BsArrowBarUp } from "react-icons/bs";
import { IoIosPersonAdd, IoMdNotificationsOutline } from "react-icons/io";
import { useTranslation } from 'react-i18next';
import Flag from 'react-flags-select';
import { MdLanguage } from "react-icons/md";
import { useUserInfo } from "auth/userInfo";
import { useQuery } from 'react-query';
import { managehWorkshops } from "api/api";
import { FaEye } from "react-icons/fa";
import { logoutUser } from "auth/auth";
import Swal from 'sweetalert2'; 
import moment from 'moment'

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const [data, setData] = useState([])

  const { i18n, t } = useTranslation();
  const { data: userInfo, isLoading } = useUserInfo();

  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [workshopsCount, setWorkshopsCount] = useState(0)
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const delay = 300; 

    const timeoutId = setTimeout(() => {
      setDebouncedQuery(query);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [query]);

  useEffect(() => {
    if (debouncedQuery) {
      setLoading(true); 
      const jwt = localStorage.getItem('token');
      fetch(`https://api.carwisi.com/search/v1/admin-search?search=${debouncedQuery}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setSuggestions(data);
          setLoading(false); 
        })
        .catch((error) => {
          console.error('Error fetching data', error);
          setLoading(false);
        });
    }
  }, [debouncedQuery]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };
  const Loading = () => {
    return (
      <div role="status" className="flex items-center justify-center">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#524442]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>
    )
  }

  useEffect(() => {
    const jwt = localStorage.getItem('token');
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://api.carwisi.com/user/v1/manage_workshop', {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch workshop');
        }
        const workshopData = await response.json();
        setData(workshopData);
        const count = data ? data?.length : 0;
        setWorkshopsCount(count)
      } catch (error) {
        console.error('Error fetching workshops', error);
      }
    };

    fetchUsers();
  }, [data]);

  const handleStatusChange = async (status, id) => {
    try {
      const jwt = localStorage.getItem('token');
      const response = await fetch(`https://api.carwisi.com/user/${id}/approve_refuse`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify({ status }),
      });

      if (!response.ok) {
        throw new Error(`Failed to update status: ${response.statusText}`);
      }
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
  
      Toast.fire({
        icon: 'success',
        title: t('You Have Update Workshop Successfully'),
      }).then(() => {
        navigate('/admin/workshop')
      });
    } catch (error) {
      console.error('Error updating status:', error.message);
    }
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    logoutUser();
    navigate('/auth')
  };

  if (isLoading) {
    // Handle loading state
    return (<div className="flex items-center mt-[120px] justify-center h-full">
      <div role="status" className="flex items-center justify-center">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#524442]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>
    </div>)
  }


  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    document.documentElement.dir = i18n.dir();
  };
  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className={`ml-${i18n.dir() === 'rtl' ? '[6px]' : '[6px]'} transition-all`}>
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            {t('Pages')}
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {t(brandText)}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[350px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[415px] xl:gap-2">
        <div className="flex h-full flex-col items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            value={query}
            onChange={handleInputChange}
            placeholder={t("Search")}
            class="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
          </div>
  {query && (
    <div className="absolute z-10 mt-16 ml-[200px] md:ml-0 max-h-[300px] overflow-y-auto w-full backdrop-blur-lg dark:bg-navy-900 dark:text-white bg-white/80 border border-gray-300 rounded-md shadow-lg p-2">
      {loading && <Loading />} 
  {suggestions && suggestions?.workShops && suggestions.workShops.length > 0 && (
    <div>
      <div className="h-5 border-b-2 border-black  text-ms text-center">
        <span className="bg-white dark:bg-navy-900 dark:text-white px-5">{t('WorkShops')}</span>
      </div>
      {suggestions.workShops.map((workshop) => (
        <Link to={`/admin/update-workshop/${workshop._id}`}>
          <div key={workshop._id} className="p-2 hover:bg-gray-100 hover:dark:bg-navy-700 cursor-pointer" onClick={() => setQuery('')}>
          <div className="flex items-center gap-4">
            <div className="relative me-4">
              <img
                className="w-10 h-10 rounded-full"
                src={workshop.avatar || require('../../assets/img/dashboards/Logo-English.png')}
                alt="carwisi"
              />
              <span
                className={`top-0 start-7 absolute w-3 h-3 border-2 border-white dark:border-gray-800 rounded-full ${
                  workshop.isOnline ? 'bg-green-500' : 'bg-red-500'
                }`}
              ></span>
            </div>
            <div className="flex items-center justify-center gap-3">
              <p className="font-bold text-black dark:text-white">{workshop.workShopName}</p>
              <p className="font-bold text-black dark:text-white">{workshop.workShopOwnerName}</p>
            </div>
          </div>
        </div>
        </Link>
      ))}
    </div>
  )}
  {suggestions && suggestions?.users && suggestions.users.length > 0 && (
    <div>
      <div className="h-5 border-b-2 border-black text-ms text-center">
        <span className="bg-white dark:bg-navy-900 dark:text-white px-5">{t('Users')}</span>
      </div>
      {suggestions.users.map((user) => (
        <Link to={`/admin/update-user/${user._id}`}>
                  <div key={user._id} className="p-2 hover:bg-gray-100 hover:dark:bg-navy-700 cursor-pointer" onClick={() => setQuery('')}>
          <div className="flex items-center gap-4">
          <img
              className="w-10 h-10 rounded-full"
              src={user.avatar || require('../../assets/img/dashboards/Logo-English.png')}
              alt="carwisi"
            />
              <div className="flex items-center justify-center gap-3">
              <p className="font-bold text-black dark:text-white">{user.fullName}</p>
              <p className="font-bold text-black dark:text-white">{user.username}</p>
            </div>
          </div>
        </div>
        </Link>
      ))}
    </div>
  )}
  {suggestions && suggestions?.cars && suggestions.cars.length > 0 && (
    <div>
      <div className="h-5 border-b-2 border-black text-ms text-center">
        <span className="bg-white dark:bg-navy-900 dark:text-white px-5">{t('Cars')}</span>
      </div>
      {suggestions.cars.map((car) => (
        <Link to={'/admin/user-auth'}>
              <div key={car._id} className="p-2 hover:bg-gray-100 hover:dark:bg-navy-700 cursor-pointer" onClick={() => setQuery('')}>
          <div className="flex items-center gap-4">
          <img
              className="w-10 h-10 rounded-full"
              src={require('../../assets/img/dashboards/Logo-English.png')}
              alt="carwisi"
            />
            <div className="flex items-center justify-center gap-3">
            <p className="font-bold text-black dark:text-white">{car.carModel}</p>
            <p className="font-bold text-black dark:text-white">{car.car_plate_number}</p>
            </div>
          </div>
        </div>
        </Link>
      ))}
    </div>
  )}
</div>
  )}
        </div>
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        <Dropdown
  button={
    <div className="relative inline-flex w-fit">
      {workshopsCount > 0 && (
              <div className="absolute bottom-auto left-auto right-[2.5px] top-[1.5px] z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-red-500 px-1 py-[2px] text-center align-baseline text-xs font-bold leading-none text-white">
              {workshopsCount}
            </div>
      )}
        <p className="cursor-pointer">
      <IoIosPersonAdd className="h-6 w-6 text-gray-600 dark:text-white" />
    </p>
    </div>
  }
  animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
  children={
    <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
{Array.isArray(data) && data.length > 0 ? (
  data?.map((d, index) => (
    <div
      key={index}
      className={`flex flex-col gap-4 ${
        index !== data.length - 1 ? 'border-b border-gray-500 dark:border-gray-700' : ''
      }`}
    >
      <Link to={`/admin/view-workshop/${d._id}`}>
        {moment().diff(d.createdAt, 'hours') <= 24 && (
                  <span className="text-xs font-bold uppercase px-2 mt-7 mr-2 text-green-900 dark:text-white bg-green-400 border rounded-full absolute top-0 right-0">
                  {t('New')}
                </span>
        )}
        <div className="flex items-center gap-4">
          <img
            src={require('../../assets/img/avatars/Logo-English.png')}
            alt="request"
            className="w-11 h-11 rounded-full shadow-md"
          />
          <div className="flex flex-col gap-1">
            <p className="font-bold text-black dark:text-white">{d.workShopName}</p>
            <p className="text-gray-500 dark:text-gray-300">{d.workShopOwnerName}</p>
          </div>
        </div>
      </Link>

      <div className="mt-auto flex justify-between items-center mb-2">
        <div>
          <p className="text-sm text-gray-500 dark:text-gray-300">
            {moment(d.createdAt).format('HH:mm')}
          </p>
        </div>
        <div className="flex gap-3">
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-md"
            onClick={() => handleStatusChange('APPROVED', d._id)}
          >
            Accept
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={() => handleStatusChange('REFUSED', d._id)}
          >
            Refuse
          </button>
        </div>
      </div>
    </div>
  ))
) : (
  <p>No Workshop</p>
)}

    </div>
  }
  classNames={`py-2 top-4 ${i18n.dir() === 'rtl' ? '-right-[230px] md:-right-[440px]' : '-left-[230px] md:-left-[440px]'} w-max`}
        >

        </Dropdown>
        <Dropdown
        button={
          <p className="cursor-pointer">
          <MdLanguage className="h-5 w-5 text-gray-600 dark:text-white"/>
          </p>
        }
        animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
        children={
          <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[79px]">
          <div className="flex items-center justify-center mt-2 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
          <Flag
              showSelectedLabel={false}
              countries={["SA", "US"]}
              selected={i18n.language === 'en' ? 'US' : 'SA'} 
              onSelect={(countryCode) => changeLanguage(countryCode === 'US' ? 'en' : 'ar')}
            />
            <style jsx>{`
          .ReactFlagsSelect-module_selectOptions__3LNBJ{
            ${darkmode ? 'background: #1B254B; color: #ffffff; box-shadow: none;' : ''}
          }
          .ReactFlagsSelect-module_selectOptions__3LNBJ :hover {
            ${darkmode ? 'background: #1B254B; color: #1B254B ; box-shadow: none;' : ''}
          }
        `}</style>

          </div>
        </div>
        }
        classNames={`py-2 top-4 -left-[230px] md:-left-[44px] w-max`}
        >
        </Dropdown>


        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-5 w-5 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-5 w-5 text-gray-600 dark:text-white" />
          )}
        </div>
        
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={isLoading ? (
                <Loading/>
              ) : userInfo?.avatar || require('../../assets/img/dashboards/Logo-English.png')}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 {t('Hey')}, {isLoading ? (
                <Loading/>
              ) : userInfo?.fullName}
                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-4">
                <Link to={'/admin/profile'}>
                <a
                  href=" "
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                 {t('My_profile')}
                </a>
                </Link>
                <a
                href=" "
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                  onClick={handleLogout}
                >
                  {t('Logout')}
                </a>
              </div>
            </div>
          }
          classNames={`py-2 top-8 ${i18n.dir() === 'rtl' ? '-right-[180px]' : '-left-[180px]'} w-max`}
        />
      </div>
    </nav>
  );
};

export default Navbar;
