import { useQuery } from 'react-query';
import { jwtDecode } from 'jwt-decode'
import { getAuthToken } from './auth';
export const checkAuth = async (t) => {
    const token = getAuthToken()

    if(!token) {
        throw new Error('User not authenticated');
    }

    try{
        const decodedToken = jwtDecode(token)

        if (decodedToken.userRole !== 'ADMIN') {
            throw new Error(t('You Are Not ADMIN'));
        }

        return true
    }catch(e) {
        throw new Error (t('You Are Not ADMIN'));
    }
}

const useAuth = () => {
    return useQuery('auth', checkAuth);
  };
  
  export default useAuth;