import { Route, Navigate } from 'react-router-dom';
import { getAuthToken } from './auth';
;


const ProtectedRoute = ({ children }) => {
    const isAuth = getAuthToken();
  
    return isAuth ? children : <Navigate to="/auth/sign-in" />;
  };
  
  export default ProtectedRoute;