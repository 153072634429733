import Card from "components/card";
import React, {useState } from "react";
import Swal from 'sweetalert2'; 
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'

const Upload = () => {
  const [avatar, setAvatar] = useState(null);
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleProfileImage = async () => {
    const jwt = localStorage.getItem('token');

    try {
      const formData = new FormData();
      formData.append('avatar', avatar);

      const response = await fetch('https://api.carwisi.com/user/v1/me/images_profile', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update user information');
      }

      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
  
      Toast.fire({
        icon: 'success',
        title: t('You Have Update Profile Image Successfully'),
      }).then(() => {
        navigate('/admin/default')
      });
    } catch (error) {
      console.error('Error updating profile', error);
    }
  };
  return (
    <Card className="grid h-full w-full grid-cols-1 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
      <div class="col-span-5 h-full w-full rounded-xl bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6">
    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">{t('Upload Files')}</span></p>
            <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG</p>
        </div>
        <input id="dropzone-file" type="file" name="avatar" onChange={(event) => setAvatar(event.target.files[0])} class="hidden"  />
    </label>
    </div>
    <div className="col-span-5 flex h-full w-full flex-col justify-center overflow-hidden rounded-xl bg-white pl-3 pb-4 dark:!bg-navy-800">
      <button
          onClick={handleProfileImage}
          className="linear mt-4 flex items-center justify-center rounded-xl bg-brand-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          {t('Upload Image Now')}
        </button>
    </div>
  </Card>
  );
};

export default Upload;
