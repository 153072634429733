import axios from 'axios'
import i18n from 'i18next';
export const fetchUsers = async () => {
    const jwt = localStorage.getItem('token');
    const response = await fetch('https://api.carwisi.com/user/v1/all_users?fields=username,fullName', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch users');
    }
  
    return response.json();
  };

  export const forgotPassword = async (email) => {
    const currentLanguage = i18n.language || 'en';
  
    try {
      const response = await axios.post(`https://api.carwisi.com/auth/v1/user_forgetpassword?lang=${currentLanguage}`, {
        email,
      });
  
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed on forgot password request');
    }
  };

  export const verifyCode = async (email, code) => {
    const currentLanguage = i18n.language || 'en';
  
    try {
      const response = await axios.post(`https://api.carwisi.com/auth/v1/user_verify_forgot_password?lang=${currentLanguage}`, {
        email,
        code
      });
  
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed on forgot password request');
    }
  };
  
  export const resetPassword = async (email, password) => {
    const currentLanguage = i18n.language || 'en';
  
    try {
      const response = await axios.post(`https://api.carwisi.com/auth/v1/user_reset_forgot_password?lang=${currentLanguage}`, {
        email,
        password,
      });
  
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed on reset password request');
    }
  };

export const loginUser = async (email, password) => {
    const currentLanguage = i18n.language || 'en';
  
    try {
      const response = await axios.post(
        `https://api.carwisi.com/auth/v1/user_signin?lang=${currentLanguage}`,
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      const data = response.data;
  
      localStorage.setItem('token', data.data.accessToken);
  
      return data;
    } catch (error) {
      let errorMessage = 'Failed on sign in request';
  
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
  
      throw new Error(errorMessage);
    }
  };
  
export const deleteUser = async (userId) => {
    const jwt = localStorage.getItem('token');
    const response = await fetch(`http://api.carwisi.com:4000/user/v1/${userId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to delete user');
    }
  
    return userId;
  };





export const fetchWorkshops = async () => {
    const jwt = localStorage.getItem('token');
    const response = await fetch('https://api.carwisi.com/workshop/v1/all_workshop', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch workshops');
    }
  
    return response.json();
  };

  export const managehWorkshops = async () => {
    const jwt = localStorage.getItem('token');
    const response = await fetch('https://api.carwisi.com/user/v1/manage_workshop', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch workshops');
    }
  
    return response.json();
  };
  
export const deleteWorkshop = async (workshopId) => {
    const jwt = localStorage.getItem('token');
    const response = await fetch(`http://api.carwisi.com:4000/workshop/v1/${workshopId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to delete workshop');
    }
  
    return workshopId;
};

export const updateUserProfile = async ({ jwt, data }) => {
    const apiUrl = 'http://api.carwisi.com:4000/user/v1/me/profile';
  
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(data),
    });
  
    if (!response.ok) {
      throw new Error('Failed to update user information');
    }
  
    return response.json();
  };
  
export const uploadProfileImage = async ({ jwt, avatar }) => {
    const formData = new FormData();
    formData.append('avatar', avatar);
  
    const response = await fetch('http://api.carwisi.com:4000/user/v1/me/images_profile', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: formData,
    });
  
    if (!response.ok) {
      throw new Error('Failed to update user information');
    }
  
    return response.json();
};
  
export const updateWorkShopProfile = async ({ id, jwt, user }) => {
    const response = await fetch(`http://api.carwisi.com:4000/workshop/v1/update-workshop/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(user),
    });
  
    if (!response.ok) {
      throw new Error('Failed to update user profile');
    }
  
    return response.json();
  };

export const fetchUser = async (id, jwt) => {
    const response = await fetch(`http://api.carwisi.com:4000/user/v1/user/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch user');
    }
  
    return response.json();
  };
  
export const updateUserInfo = async ({ id, jwt, user }) => {
    const response = await fetch(`http://api.carwisi.com:4000/user/v1/update-user/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(user),
    });
  
    if (!response.ok) {
      throw new Error('Failed to update user profile');
    }
  
    return response.json();
  };

export const createUser = async ({ user, jwt }) => {
    const response = await fetch('http://api.carwisi.com:4000/user/v1/create-user-admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(user),
    });
  
    if (!response.ok) {
      throw new Error('Failed to create user');
    }
  
    return response.json();
  };

export const handleCreateWorkshop = async (workshop) => {
    const jwt = localStorage.getItem('token');
    try {
      const response = await fetch('http://api.carwisi.com:4000/workshop/v1/create-workshop-admin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(workshop),
      });

      if (!response.ok) {
        throw new Error('Failed to create workshop');
      }
    } catch (error) {
      console.error('Error creating workshop', error);
    }
  };

export const fetchWorkshop = async (id) => {
  const jwt = localStorage.getItem('token');
    const response = await fetch(`https://api.carwisi.com/workshop/v1/${id}/workshop`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch workshop');
    }
  
    return response.json();
  };
  
export const updateWorkshopStatus = async ({ id, jwt, status }) => {
    const response = await fetch(`http://api.carwisi.com:4000/user/${id}/approve_refuse`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ status }),
    });
  
    if (!response.ok) {
      throw new Error(`Failed to update status: ${response.statusText}`);
    }
  
    return response.json();
  };
  
  