
import { columnsDataColumns } from "../variables/columnsData";  
import UserTable from "./UserTable";
  const Users = () => {
    return (
      <div>
        <div className="mt-10 grid h-full grid-cols-1 gap-3">
          <UserTable
          columnsData={columnsDataColumns}
          />
        </div>
      </div>
    );
  };
  
  export default Users;
  