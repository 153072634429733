import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from "react";
import { useMutation, useQueryClient } from 'react-query';
import { loginUser } from "api/api";
import { forgotPassword } from "api/api";
import { resetPassword } from "api/api";
import { verifyCode } from "api/api";
import { checkAuth } from "auth/useAuth";
import Swal from 'sweetalert2';
import CardAuth from "components/card/CarAuth";

export default function SignIn() {
  const { t } = useTranslation();
  const queryClient = new useQueryClient();
  const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
  const [resetPasswordStep, setResetPasswordStep] = useState(1);
  const [authError, setAuthError] = useState(null)
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [token, setToken] = useState()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      forget: {
        email: ''
      },
      newPassword: '',
      confirmPassword: '',
      token: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
      password: Yup.string().min(8, t('Password must be at least 8 characters')).required(
        t('Password is required')
      ),
      forget: Yup.object().shape({
        email: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
      }),
      newPassword: Yup.string()
      .min(8, t('Password must be at least 8 characters'))
      .required(t('New Password is required')),
      confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('Passwords must match'))
      .required(t('Confirm Password is required')),
      token: Yup.number().min(6, t('Enter The 6 Number Of Code')).required(t('Code Is Required'))
    }),
    onSubmit: async (values) => {
      switch (resetPasswordStep) {
        case 2:
          try {
            await verifyCodeMutation({ email: values.email, token: values.resetCode });
            setResetPasswordStep(3);
          } catch (error) {
            console.error('Verify Code failed', error);
            setAuthError(error.message);
          }
          break;
        case 3:
          try {
            await resetPasswordMutation({ email: values.email, password: values.newPassword });
            // Invalidate login query to refetch user data after resetting the password
            queryClient.invalidateQueries('login');
            setForgotPasswordClicked(false);
          } catch (error) {
            console.error('Reset Password failed', error);
            setAuthError(error.message);
          }
          break;
        default:
          break;
      }
    },
  });
  const forgotPasswordFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
    }),
    onSubmit: (values) => {
      console.log('here')
    },
  });

  const {mutate: LoginMutate, isLoading: isLoadingLogin} = useMutation(() => loginUser(formik.values.email, formik.values.password), {
    onSuccess: async (data) => {
      const isAdmin = await checkAuth(t);
  
      if (isAdmin) {
        const { from } = location.state || { from: { pathname: 'admin/default' } };
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: t('success'),
          title: t('You Have Login Successfully'),
        }).then(() => {
          navigate('/');
        })
      } else {
        console.error('User is not an admin');
      }
    },
    onError: (error) => {
      console.error('Login error', error);
      setAuthError(error.message);
    },
  });

  const {mutate: forgotPasswordMutation, isLoading: isForgetLoading} = useMutation(() => forgotPassword(email), {
    onSuccess: (data) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      Toast.fire({
        icon: t('success'),
        title: t('You Have Send Code Successfully To Your Email'),
      })
      setResetPasswordStep(2);
    },
    onError: (error) => {
      // Handle error, e.g., show an error message
      console.error('Forgot Password error', error);
      setAuthError(error.message);
    },
  });

  const {mutate: resetPasswordMutation, isLoading: isResetLoading} = useMutation(() => resetPassword(email, password), {
    onSuccess: (data) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      Toast.fire({
        icon: t('success'),
        title: t('You Have Set New Password Successfully You Can Login Now'),
      }).then(() => {
        navigate('/auth/sign-in')
      })
      setForgotPasswordClicked(false);
    },
    onError: (error) => {
      // Handle error, e.g., show an error message
      console.error('Reset Password error', error);
      setAuthError(error.message);
    },
  });

  const {mutate: verifyCodeMutation, isLoading: isVerifyLoading} = useMutation(() => verifyCode(email, parseInt(token, 10)), {
    onSuccess: (data) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      Toast.fire({
        icon: t('success'),
        title: t('You Have Enter Code Successfully You Can Enter New Password'),
      })
      setResetPasswordStep(3);
      queryClient.invalidateQueries('login');
    },
    onError: (error) => {
      // Handle error, e.g., show an error message
      console.error('Verify Code error', error);
      setAuthError(error.message);
    },
  });

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await LoginMutate();
    } catch (error) {
      console.log('Login failed', error);
    }
  };

  const handleForgotPasswordClick = (event) => {
    event.preventDefault();
    setForgotPasswordClicked((prevState) => !prevState);
    setResetPasswordStep(1);
    setAuthError(null)
  };

  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    try {
      await forgotPasswordMutation();
    } catch (error) {
      console.error('Forgot Password failed', error);
      setAuthError(error.message);
    }
  };

  const handleResetCodeSubmit = async (event) => {
    event.preventDefault();
    try {
      await verifyCodeMutation();
    } catch (error) {
      console.error('Reset Password failed', error);
      setAuthError(error.message);
    }
  };

  const handleNewPasswordSubmit = async (event) => {
    event.preventDefault();
    try {
      await resetPasswordMutation();
    } catch (error) {
      console.error('Verify Code failed', error);
      setAuthError(error.message);
    }
  };
  return (
    <form>
      <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {t('Sign In')}
        </h4>
        <div className="mb-4 flex items-center gap-3">
        {authError && (
        <div className="mt-4 w-full">
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  {authError}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        </div>
        {/* Email */}
        <div>
        <InputField
          variant="auth"
          extra="mb-3"
          label={t("Email")}
          placeholder="mail@simmmple.com"
          id="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          {...formik.getFieldProps('email')}
          className={`mt-1 p-2 w-full border rounded-md ${
            formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-300'
          }`}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="text-red-500 text-sm">{formik.errors.email}</div>
        )}
        </div>

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label={t("Password")}
          placeholder={t("Password")}
          id="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          {...formik.getFieldProps('password')}
          className={`mt-1 p-2 w-full border rounded-md ${
            formik.touched.password && formik.errors.password ? 'border-red-500' : 'border-gray-300'
          }`}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="text-red-500 text-sm">{formik.errors.password}</div>
        )}
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              {t('keepM')}
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
            onClick={handleForgotPasswordClick}
          >
            {t('forgotPassword')}
          </a>
        </div>
        {forgotPasswordClicked && resetPasswordStep === 1 && (
            // Render Forgot Password Modal
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white dark:bg-navy-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <h2 className="text-2xl font-bold text-navy-700 dark:text-white">
                    {t('forgotPassword')}
                    </h2>
                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                      {t('enterEmail')}
                    </p>
                    <div className="mb-4 flex items-center gap-3">
        {authError && (
        <div className="mt-4 w-full">
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  {authError}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        </div>
                  </div>
                  <div className="mt-4">
                      <InputField
                        variant="auth"
                        label={t("Email")}
                        placeholder="mail@simmmple.com"
                        id="forgotPasswordEmail"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`mt-1 p-2 w-full border rounded-md ${
                          forgotPasswordFormik.touched.email && forgotPasswordFormik.errors.email
                            ? 'border-red-500'
                            : 'border-gray-300'
                        }`}
                      />
                      {forgotPasswordFormik.touched.email && forgotPasswordFormik.errors.email && (
                        <div className="text-red-500 text-sm">{forgotPasswordFormik.errors.email}</div>
                      )}
                    </div>
                    <div className="mt-4">
                      <button
                        type="submit"
                        onClick={handleForgotPasswordSubmit}
                        className="linear w-full rounded-xl bg-[#E2A91B] py-2 text-base font-medium text-white transition duration-200 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      >
                        {isForgetLoading ? ((<div role="status" className="flex items-center justify-center">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#524442]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>)) : t('resetP')}
                      </button>
                    </div>
                  
                </div>
              </div>
            </div>
          )}
        {forgotPasswordClicked && resetPasswordStep === 2 && (
                      <div className="fixed inset-0 z-50 overflow-y-auto">
                      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
        
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
                        <div className="inline-block align-bottom bg-white dark:bg-navy-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                          <div>
                            <h2 className="text-2xl font-bold text-navy-700 dark:text-white">
                              {t('verifyC')}
                            </h2>
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                              {t('enterC')}
                            </p>
                            <div className="mb-4 flex items-center gap-3">
        {authError && (
        <div className="mt-4 w-full">
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  {authError}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        </div>
                          </div>
                          
                            <div className="mt-4">
                            <InputField
                                variant="auth"
                                label={t('enterC')}
                                placeholder={t('enterC')}
                                id="resetCode"
                                type="number"
                                value={token}
                                onChange={(e) => setToken(e.target.value)}
                                className={`mt-1 p-2 w-full border rounded-md ${
                                  formik.touched.token && formik.errors.token
                                    ? 'border-red-500'
                                    : 'border-gray-300'
                                }`}
                              />
                              {formik.touched.token && formik.errors.token && (
                                <div className="text-red-500 text-sm">{formik.errors.token}</div>
                              )}
                            </div>
                            <div className="mt-4">
                              <button
                                type="submit"
                                className="linear w-full rounded-xl bg-[#E2A91B] py-2 text-base font-medium text-white transition duration-200 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                onClick={handleResetCodeSubmit}
                              >
                                {isVerifyLoading ? ((<div role="status" className="flex items-center justify-center">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#524442]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>)) : t('verifyC')}
                              </button>
                            </div>
                         
                        </div>
                      </div>
                    </div>
        )}
        {forgotPasswordClicked && resetPasswordStep === 3 && (
                      <div className="fixed inset-0 z-50 overflow-y-auto">
                      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
        
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
                        <div className="inline-block align-bottom bg-white dark:bg-navy-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                          <div>
                            <h2 className="text-2xl font-bold text-navy-700 dark:text-white">
                              {t('newPassword')}
                            </h2>
                            <div className="mb-4 flex items-center gap-3">
        {authError && (
        <div className="mt-4 w-full">
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  {authError}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        </div>
                          </div>
                          
                            <div className="mt-4">
                              <InputField
                                variant="auth"
                                label={t("Email")}
                                placeholder="mail@simmmple.com"
                                id="forgotPasswordEmail"
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`mt-1 p-2 w-full border rounded-md ${
                                  formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-300'
                                }`}
                              />
                              {formik.touched.email && formik.errors.email && (
                                <div className="text-red-500 text-sm">{formik.errors.email}</div>
                              )}
                            </div>
                            <div className="mt-4">
                              <InputField
                                variant="auth"
                                label={t('Password')}
                                placeholder={t('*********')}
                                id="newPassword"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={`mt-1 p-2 w-full border rounded-md ${
                                  formik.touched.newPassword && formik.errors.newPassword ? 'border-red-500' : 'border-gray-300'
                                }`}
                              />
                              {formik.touched.newPassword && formik.errors.newPassword && (
                                <div className="text-red-500 text-sm">{formik.errors.newPassword}</div>
                              )}
                            </div>
                            <div className="mt-4">
                                <InputField
                                  variant="auth"
                                  label={t('ConfirmPassword')}
                                  placeholder={t('*********')}
                                  id="confirmPassword"
                                  type="password"
                                  className={`mt-1 p-2 w-full border rounded-md ${
                                    formik.touched.confirmPassword && formik.errors.confirmPassword
                                      ? 'border-red-500'
                                      : 'border-gray-300'
                                  }`}
                                />
                                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                  <div className="text-red-500 text-sm">{formik.errors.confirmPassword}</div>
                                )}
                              </div>
                            <div className="mt-4">
                              <button
                                type="submit"
                                className="linear w-full rounded-xl bg-[#E2A91B] py-2 text-base font-medium text-white transition duration-200 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                onClick={handleNewPasswordSubmit}
                              >
                                {isResetLoading ? ((<div role="status" className="flex items-center justify-center">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#524442]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>)) : t('newPassword')}
                              </button>
                            </div>
                          
                        </div>
                      </div>
                    </div>
        )}
          <button 
          className="linear mt-2 w-full rounded-xl bg-[#E2A91B] py-[12px] text-base font-medium text-white transition duration-200  dark:text-white "
          onClick={handleLogin}
          >
          {isLoadingLogin ? (<div role="status" className="flex items-center justify-center">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#524442]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>) : t('Sign in')}
            </button>
      </div>
    </div>
    </form>
  );
}
