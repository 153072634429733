import React from 'react'
import NotificationComponent from './notificationTable'
import { columnsDataDevelopment } from '../variables/columnsData'

const Notifications = () => {
  return (
    <div>
    <div className="mt-10 grid h-full grid-cols-1 gap-3">
      <NotificationComponent
      columnsData={columnsDataDevelopment}
      />
    </div>
  </div>
  )
}

export default Notifications