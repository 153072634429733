import {
  columnsDataComplex,
} from "./variables/columnsData";
import ComplexTable from "./components/ComplexTable";

const Tables = () => {
  return (
    <div>
      <div className="mt-10 grid h-full grid-cols-1 gap-3">
        <ComplexTable
          columnsData={columnsDataComplex}
        />
      </div>
    </div>
  );
};

export default Tables;
